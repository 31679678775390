<template>
    <div class="share" ref="share">
        <!-- 头部导航 -->
        <HeaderBar title="分享好友砍价" style="z-index:99999" />
        <div class="shareBox">
            <div id="capture">
                <div class="capture-img">
                    <div class="logo">
                        <img src="@/assets/bargain/mooby.png" alt="">
                        <span v-if="goods.phone">{{goods.phone}}</span>
                        <span v-else>{{goods.email}}</span>
                    </div>
                    <img class="share-bg" :src="goodsImg" alt="">
                    <div class="top-tip">
                        <div class="discount">
                            
                            <img src="@/assets/bargain/off.png" alt="">
                        </div>
                        <div class="surplus">
                            <p>打折套票剩余</p>
                            <p><span>{{goods.stock}}</span> 张</p>
                        </div>
                    </div>
                </div>
                <div class="code-tips">
                    <div class="qrcode">
                        <div class="qrCodeDiv" ref="qrCodeDiv"></div>
                    </div>
                    <div class="tips">
                        <p>长按识别二维码，帮我砍夜市半价套票</p>
                        <p>MoobyYoho夜市优惠套票官方售卖</p>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="showShare">
            <div class="share-pos">
                <div class="dashed">
                    <div class="left">
                        <img src="@/assets/bargain/save.png" alt="">
                        <p>长按保存图片<br>分享给好友</p>
                    </div>
                    <div class="line"></div>
                    <div class="right">
                        <img src="@/assets/bargain/qecode.png" alt="">
                        <p>好友扫码<br>即可帮你砍价</p>
                    </div>
                </div>
            </div>
            <img class="share-img" :src="imgSrc" alt="" v-show="isShow">
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'
import HeaderBar from '@/components/zh/m-header.vue'
import { getImg } from '@/api/zh/detail.js'
export default {
    name: 'share',
    components: { HeaderBar },
    data(){
        return {
            shareData:{},
            goodsImg: '',
            imgSrc: '',
            isShow: false,
            goods: {}
        }
    },
    methods:{
        getImgHandle() {
            getImg(this.goods.id,{barcode: this.goods.barcode}).then(res => {
                if(res) {
                    this.goodsImg = res.data
                    
                    setTimeout(() => {
                        this.downloadImg()
                    }, 100)
                }
            })
        },
        getData() {
            this.goods = JSON.parse(sessionStorage.getItem('bargainShare'))
            if(this.goods.phone) {
                this.goods.phone = '******' + this.goods.phone.slice(-4)
            }
            if(this.goods.email) {
                this.goods.email =  this.goods.email.substr(0, this.goods.email.indexOf("@")) + '*****' 
            }
            this.getImgHandle()
            this.$nextTick( () => { 
                setTimeout(() => {
                    new QRCode(this.$refs.qrCodeDiv, {
                        text: `https://m.moobyyoho.com/zh/help?id=` + this.$route.query.id,
                        width: 65,
                        height: 65,
                        colorDark: "#333333", //二维码颜色
                        colorLight: "#ffffff", //二维码背景色
                        correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
                    })
                },300)
            })
        },
        downloadImg() {
            let that = this
            html2canvas(document.querySelector("#capture")).then((canvas) => {
                var timer = setTimeout(() => {
                    var imgData = canvas.toDataURL('image/png');//canvas转换为图片
                    that.imgSrc = imgData
                    that.isShow = true
                    clearTimeout(timer)
                }, 0)
            }).catch(error => {
                console.log(error, 'error')
            })
            this.$store.commit('unload')
        }
    },
    created() {
        this.$store.commit('load')
        this.getData()
    },
}
</script>
<style lang="less" scope>
.share {
    position: relative;
    width:100%;
    height:100%;
    overflow: hidden;
    .shareBox{
        position: absolute;
        top:12%;
        left:50%;
        transform: translateX(-50%);
        overflow: hidden;
        border-radius: 12px;
        #capture{
            width: 310px;
            overflow: visible !important;
            font-family: Helvetica, Tahoma, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', STXihei, 'Microsoft YaHei', SimHei;
            .capture-img {
                width: 100%;
                height: 380px;
                border-radius: 12px 12px 0 0;
                position: relative;
                .logo {
                    position: absolute;
                    top: 7px;
                    left: 10px;
                    width: 130px;
                    height: 34px;
                    z-index: 2;
                    background: #fff;
                    border-radius: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 30px;
                        height: 30px;
                        margin-right: 5px;
                    }
                }
                .share-bg {
                    position: absolute;
                    top: 23px;
                    left: 0;
                    width: 100%;
                    height: 92%;
                }
                .top-tip {
                    width: 100%;
                    height: 50px;
                    position: absolute;
                    bottom: -10px;
                    left: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 12px;
                    background: url('../../../assets/bargain/tip.png') no-repeat;
                    background-size: 100% 100%;
                    .discount {
                        margin-left: 8px;
                        margin-top: 5px;
                        width: 160px;
                        height: 38px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .surplus {
                        color: #5A0000;
                        margin-right: 20px;
                        p:nth-child(2) {
                            text-align: center;
                            span {
                                font-style: italic;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            .code-tips {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding:28px 14px 10px;
                .qrcode {
                    width: 68px;
                    height: 68px;
                    flex-shrink: 0;
                    border-radius: 8px;
                    margin-right: 12px;
                    // background: #ccc;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &>div {
                        width: 63px !important;
                        height: 63px !important;
                        img {
                            width: 63px !important;
                            height: 63px !important;
                        }
                    }
                }
                .tips {
                    display: flex;
                    flex-flow: column;
                    // align-items: center;
                    font-size: 16px;
                    // p:nth-child(1) {
                    //     margin-bottom: 8px;
                    // }
                    p:nth-child(2) {
                        font-size: 12px;
                        color: #5989ED;
                    }
                }
            }
        }
    }
    .share-pos {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: 334px;
        height: 588px;
        z-index: 99;
        border: 1px solid #FFCAB3;
        border-radius: 22px;
        background-color: #fff !important;
        .dashed {
            border-top: 3px dashed #FFCAB3;
            position: absolute;
            bottom: 19px;
            left: 50%;
            transform: translateX(-50%);
            z-index:100;
            padding-top: 21px;
            display: flex;
            align-items: center;
            cursor: not-allowed;
            .left,
            .right {
                display: flex;
                align-items: center;
                img {
                    width: 38px;
                    height: 38px;
                    margin-right: 4px;
                }
                p {
                    width: 86px;
                    font-size: 14px;
                    color: #666;
                }
            }
            .line {
                background: #FFCAB3;
                width: 1px;
                height: 32px;
                margin: 0 12px;
            }
        }
    }
    .share-img{
        position: absolute;
        top:12%;
        left:50%;
        width: 311px;
        height: 470px;
        overflow: hidden;
        border-radius: 12px;
        transform: translateX(-50%);
        z-index:100;
        background: #FEFAFA;
        box-shadow: 0px 2px 8px rgba(233, 205, 205, 0.3);
    }
    
}
</style>